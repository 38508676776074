import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Types } from 'trhub-utils';

import DataGrid from '~/components/DataGrid';
import Time from '~/components/Time';

import query from './_GetEditions.gql';

const columns = [
  {
    field: 'editionLabel',
    flex: 1,
    headerName: 'Utgåva',
    renderCell: params => (
      <Link component={RouterLink} to={`/editions/${params.row.id}`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: 'sku',
    flex: 2,
    headerName: 'Produkt',
    valueGetter: params => Types.distributableProducts[params.value],
  },
  {
    field: 'deliveryDate',
    flex: 1,
    headerName: 'Utdelningsdatum',
    renderCell: params => <Time date={new Date(params.value)} />,
  },
  {
    field: 'distributionDate',
    flex: 1,
    headerName: 'Uttagsdatum',
    renderCell: params =>
      params.value && <Time date={new Date(params.value)} />,
  },
  {
    field: 'lastOrderDate',
    flex: 1,
    headerName: 'Sista best.datum',
    renderCell: params =>
      params.value && <Time date={new Date(params.value)} />,
  },
  {
    field: 'productionDate',
    flex: 1,
    headerName: 'Produktionsdatum',
    renderCell: params =>
      params.value && <Time date={new Date(params.value)} />,
  },
  {
    field: 'distributionCount',
    flex: 1,
    headerName: 'Mottagare',
  },
];

export default function EditionListResults({ params }) {
  const { sku, editionYear } = params;

  return (
    <DataGrid
      columns={columns}
      query={query}
      defaultSort="deliveryDate"
      filter={{
        sku,
        editionYear,
      }}
    />
  );
}

EditionListResults.propTypes = {
  params: PropTypes.shape({
    editionYear: PropTypes.number.isRequired,
    sku: PropTypes.string.isRequired,
  }),
};
