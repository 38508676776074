// Expects data to be of either array or object type
export default function toOptions(data, useSort = true) {
  if (useSort) {
    if (Array.isArray(data)) {
      return data
        .sort((a, b) => a.localeCompare(b))
        .map(value => ({ value, text: value }));
    } else {
      return Object.entries(data)
        .sort(([, a], [, b]) => a.localeCompare(b))
        .map(([value, text]) => ({ value, text }));
    }
  } else {
    return Array.isArray(data)
      ? data.map(value => ({ value, text: value }))
      : Object.entries(data).map(([value, text]) => ({ value, text }));
  }
}
