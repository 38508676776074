import { useApolloClient, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import getNewIssuesCount from '~/components/Layout/_GetBadgeCounts.gql';

import addIssueMutation from './_AddIssue.gql';
import IssueForm from './IssueForm';

export default function IssueAdd() {
  const apollo = useApolloClient();

  const [action] = useMutation(addIssueMutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
    refetchQueries: [{ query: getNewIssuesCount }],
  });

  const history = useHistory();

  return (
    <>
      <Breadcrumb name="Lägg till ny" />
      <IssueForm
        initialValues={{
          dispatcher: '',
          category: '',
          description: '',
          severity: '',
          status: '',
          assignee: '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const modifiedValues = {
            ...values,
            created: new Date(),
          };

          const { data } = await action({
            variables: {
              input: modifiedValues,
            },
          });

          setSubmitting(false);
          history.push(`/issues/${data.addIssue.id}`);
        }}
      />
    </>
  );
}
