import { useQuery } from '@apollo/client';
import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format, getISOWeek } from 'date-fns';
import { sv } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';
import formatPrice from '~/utils/formatPrice';

import query from './_GetAllSalesReports.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

const TransformDate = ({ dateType, date }) => {
  switch (dateType) {
    case 'weekly': {
      return (
        <>
          v
          {getISOWeek(new Date(date), {
            weekStartsOn: 1,
            firstWeekContainsDate: 4,
          })}
        </>
      );
    }
    case 'monthly': {
      const month = format(new Date(date), 'LLLL', { locale: sv });
      return <>{month.charAt(0).toUpperCase() + month.slice(1)}</>;
    }
    case 'yearly': {
      return <>{new Date(date).getFullYear()}</>;
    }
    default:
    // Do nothing
  }

  return <Time date={new Date(date)} />;
};

export default function SalesListResults({ state }) {
  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);
  const { error, data } = useQuery(query, {
    variables: {
      type: state.type,
      dateType: state.dateType,
      productId: state.productId,
      site: state.site,
      from: state.from || undefined,
      to: state.to || undefined,
      page: page,
      limit: pageSize,
    },
  });

  const res = data?.getSalesReports;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = [
    'Datum',
    state.type === 'product' ? 'Produkt' : 'Projekt',
    'Antal beställningar',
    '6% Moms',
    '25% Moms',
    'Moms totalt',
    'Totalt ex moms',
    'Totalt inkl moms',
  ];

  return (
    <>
      <TableList
        page={page}
        pageSize={pageSize}
        data={res}
        headers={headers}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
      >
        {res?.reports.length > 0 ? (
          res.reports.map((row, index) => (
            <TableRow hover key={index}>
              <TableCell className={classes.tableCellDate}>
                <TransformDate dateType={state.dateType} date={row.date} />
              </TableCell>
              <TableCell>
                {state.type === 'product'
                  ? row.product.name
                  : Types.sites[row.site]}
              </TableCell>
              <TableCell>{row.totalOrders}</TableCell>
              <TableCell>{formatPrice(row.totalVatSixPct)}</TableCell>
              <TableCell>{formatPrice(row.totalVatTwentyFivePct)}</TableCell>
              <TableCell>{formatPrice(row.totalVat)}</TableCell>
              <TableCell>{formatPrice(row.totalIncomeExcludingVat)}</TableCell>
              <TableCell>{formatPrice(row.totalIncomeIncludingVat)}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={10} align="center">
              Inga försäljningsstatistik finns för denna filtrering. Prova att
              ange en annan produkt/varumärke, eller ett annat datumintervall.
            </TableCell>
          </TableRow>
        )}
        {res?.reports.length > 0 && res.totalReport && (
          <TableRow className={classes.tableRowSum}>
            <TableCell>Total:</TableCell>
            <TableCell>
              {state.type === 'product' ? 'Produkt' : 'Projekt'}
            </TableCell>
            <TableCell>{res.totalReport.totalOrders}</TableCell>
            <TableCell>{formatPrice(res.totalReport.totalVatSixPct)}</TableCell>
            <TableCell>
              {formatPrice(res.totalReport.totalVatTwentyFivePct)}
            </TableCell>
            <TableCell>{formatPrice(res.totalReport.totalVat)}</TableCell>
            <TableCell>
              {formatPrice(res.totalReport.totalIncomeExcludingVat)}
            </TableCell>
            <TableCell>
              {formatPrice(res.totalReport.totalIncomeIncludingVat)}
            </TableCell>
          </TableRow>
        )}
      </TableList>
    </>
  );
}

SalesListResults.propTypes = {
  state: PropTypes.shape({
    type: PropTypes.string.isRequired,
    dateType: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    site: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    graphYear: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
};

TransformDate.propTypes = {
  date: PropTypes.string.isRequired,
  dateType: PropTypes.string.isRequired,
};
