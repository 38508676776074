import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';
import ErrorMessage from '~/components/ErrorMessage';

import query from './_GetOrder.gql';
import OrderDetail from './OrderDetail';
import OrderEdit from './OrderEdit';
import OrderRefund from './OrderRefund';

export default function Order() {
  const { path } = useRouteMatch();
  const { order } = useParams();
  const { error, loading, data } = useQuery(query, {
    variables: { order },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Breadcrumb name={data.getOrder.id} />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <OrderDetail data={data} />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/edit`} exact>
          <OrderEdit />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/refund`} exact>
          <OrderRefund data={data} />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
