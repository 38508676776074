import { useMutation } from '@apollo/client';
import { Box, IconButton, Link } from '@material-ui/core';
import { Check, Delete } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import DataGrid from '~/components/DataGrid';
import getOpenRefundsCount from '~/components/Layout/_GetBadgeCounts.gql';
import PaymentMethodIcon from '~/components/PaymentMethodIcon';
import Time from '~/components/Time';
import formatPrice from '~/utils/formatPrice';

import attestMutation from './_AttestRefund.gql';
import query from './_GetRefunds.gql';
import removeMutation from './_RemoveRefund.gql';

export default function StatusListResults() {
  const [error, setError] = useState('');
  const [attestAction] = useMutation(attestMutation, {
    onError: async error => {
      setError(error.message);
    },
    refetchQueries: [{ query: getOpenRefundsCount }, 'GetRefunds'],
  });
  const [removeAction] = useMutation(removeMutation, {
    onError: async error => {
      setError(error.message);
    },
    refetchQueries: [{ query: getOpenRefundsCount }, 'GetRefunds'],
  });

  const columns = [
    {
      field: 'paymentMethod',
      width: 50,
      headerName: ' ',
      renderCell: params => <PaymentMethodIcon paymentMethod={params.value} />,
    },
    {
      field: 'order',
      flex: 2,
      headerName: 'Order',
      renderCell: params => (
        <Link component={RouterLink} to={`/order/${params.value.id}`}>
          {params.value.label}
        </Link>
      ),
    },
    {
      field: 'amount',
      flex: 1,
      headerName: 'Belopp',
      valueFormatter: params => formatPrice(params.value),
    },
    {
      field: 'created',
      flex: 1,
      headerName: 'Skapad',
      renderCell: params =>
        params.value && <Time date={new Date(params.value)} />,
    },
    {
      field: 'attestants',
      flex: 1,
      headerName: 'Attestanter',
      renderCell: params =>
        params.value && <>{params.value.map(user => user.name).join(', ')}</>,
    },
    {
      field: 'status',
      width: 150,
      headerName: ' ',
      renderCell: params =>
        params?.value && (
          <>
            <IconButton
              color="primary"
              disabled={params.value === 'closed'}
              onClick={() =>
                attestAction({
                  variables: {
                    id: params.row.id,
                  },
                })
              }
            >
              <Check />
            </IconButton>
            <IconButton
              color="primary"
              disabled={params.value === 'closed'}
              onClick={() =>
                removeAction({
                  variables: {
                    id: params.row.id,
                  },
                })
              }
            >
              <Delete />
            </IconButton>
          </>
        ),
    },
  ];

  return (
    <>
      {error.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <DataGrid
        columns={columns}
        query={query}
        defaultSort="created"
        // filter={{
        //   status,
        // }}
      />
    </>
  );
}
