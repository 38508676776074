export const sampleProductSubscription = Object.freeze({
  name: 'Travronden Digital',
  type: 'subscription',
  paymentInterval: 1,
  sku: 'TR-DIGITAL',
  site: 'travronden',
  price: 14900,
  vat: 25,
  allowedPaymentMethods: ['notification', 'autogiro', 'card'],
  costCenter: 10,
  project: 101,
  billectaId: '12345-6789-12-567',
});

export const sampleMonthlyProductSubscription = Object.freeze({
  name: 'Travronden Digital',
  type: 'subscription',
  paymentInterval: 1,
  sku: 'TR-DIGITAL',
  site: 'travronden',
  price: 14900,
  vat: 25,
  allowedPaymentMethods: ['autogiro', 'card'],
  costCenter: 10,
  project: 101,
  billectaId: '12345-6789-12-567',
});

export const sampleHalfYearlyProductSubscription = Object.freeze({
  name: 'Travronden Halvårs',
  type: 'subscription',
  paymentInterval: 6,
  sku: 'TR-DIGITAL',
  site: 'travronden',
  price: 50000,
  vat: 25,
  allowedPaymentMethods: ['notification'],
  costCenter: 10,
  project: 101,
  billectaId: '12345-6789-12-567',
});

export const sampleProductSubscriptionQuartely = Object.freeze({
  name: 'TestProdukt',
  type: 'subscription',
  paymentInterval: 3,
  sku: 'SKU-TEST',
  site: 'travronden',
  billectaId: '338eab77-3977-49d1-a286-8a4d4aa868d6',
  price: 14900,
  vat: 25,
  allowedPaymentMethods: ['notification', 'autogiro'],
  costCenter: 10,
  project: 101,
});

export const sampleProductMCSubscription = Object.freeze({
  name: 'Travronden Papper',
  type: 'subscription',
  paymentInterval: 1,
  sku: 'MC-TRT',
  site: 'travronden',
  price: 14900,
  vat: 25,
  allowedPaymentMethods: ['notification', 'autogiro'],
  costCenter: 10,
  project: 101,
});

export const sampleProductBettip = Object.freeze({
  name: 'Spelvärde - Speltips V75',
  type: 'bettip',
  sku: 'SV-TIPS-V75',
  roundDate: new Date(),
  roundId: 123456,
  site: 'travronden',
  price: 9900,
  vat: 25,
  allowedPaymentMethods: ['card', 'swish', 'invoice'],
});

export const sampleProductBook = Object.freeze({
  name: 'En liten bok',
  type: 'book',
  sku: 'TR-BOK',
  site: 'travronden',
  price: 9900,
  vat: 25,
  allowedPaymentMethods: ['card', 'swish', 'invoice'],
});
