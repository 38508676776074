import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Order from './Order';
import OrderList from './OrderList';

export default function Orders() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Ordrar" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <OrderList />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:order`}>
          <Order />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
