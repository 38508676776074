import { useApolloClient, useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';

import addEditionMutation from './_AddEdition.gql';
import EditionForm from './EditionForm';

export default function EditionAdd() {
  const apollo = useApolloClient();

  const [action] = useMutation(addEditionMutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });
  const history = useHistory();

  return (
    <>
      <Breadcrumb name="Lägg till ny" />
      <EditionForm
        initialValues={{
          sku: '',
          editionLabel: '',
          productionDate: format(new Date(), 'yyyy-MM-dd'),
          lastOrderDate: format(new Date(), 'yyyy-MM-dd'),
          distributionDate: format(new Date(), 'yyyy-MM-dd'),
          deliveryDate: format(new Date(), 'yyyy-MM-dd'),
        }}
        addEdition
        onSubmit={async (values, { setSubmitting }) => {
          const { data } = await action({
            variables: {
              input: values,
            },
          });

          setSubmitting(false);
          history.push(`/editions/${data.addEdition.id}`);
        }}
      />
    </>
  );
}
