import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { BreadcrumbsProvider } from '~/components/Breadcrumbs';
import Layout from '~/components/Layout';

import Apollo from './apollo';
import Auth from './auth';
import ErrorBoundary from './components/ErrorBoundary';
import GlobalStyles from './GlobalStyles';
import Routes from './routes';
import theme from './theme';

render(
  <ErrorBoundary>
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <BreadcrumbsProvider>
            <GlobalStyles />
            <Router>
              <Apollo>
                <Auth>
                  <Layout>
                    <Routes />
                  </Layout>
                </Auth>
              </Apollo>
            </Router>
          </BreadcrumbsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </ErrorBoundary>,
  document.querySelector('#root'),
);
