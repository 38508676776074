import { Redirect, Switch } from 'react-router-dom';

import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Attest from './Attest';
import Campaign from './Campaign';
import Edition from './Edition';
import Issue from './Issue';
import Order from './Order';
import Product from './Product';
import Deviations from './Report/Deviations';
import Income from './Report/Income';
import KeyFigures from './Report/KeyFigures';
import Sales from './Report/Sales';
import UnsubscribeReasons from './Report/UnsubscribeReasons';
import Subscription from './Subscription';
import Terms from './Terms';
import Users from './Users';

export default function Routes() {
  return (
    <Switch>
      <ErrorBoundRoute path="/users">
        <Users />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/order">
        <Order />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/products">
        <Product />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/subscriptions">
        <Subscription />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/campaigns">
        <Campaign />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/attests">
        <Attest />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/editions">
        <Edition />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/sales">
        <Sales />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/deviations">
        <Deviations />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/keyfigures">
        <KeyFigures />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/income">
        <Income />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/unsubscribe-reasons">
        <UnsubscribeReasons />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/issues">
        <Issue />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/terms">
        <Terms />
      </ErrorBoundRoute>
      <ErrorBoundRoute path="/" exact>
        <Redirect to="/users" />
      </ErrorBoundRoute>
    </Switch>
  );
}
