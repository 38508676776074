import { useQuery } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import query from './_GetReportProducts.gql';
import DialogTerminology from './dialogTerminology';
import IncomeChart from './IncomeChart';
import KeyFigureListResults from './KeyFigureListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  productTextField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default function KeyFigureList({ state, handleChange, clearState }) {
  const classes = useStyles();

  const { error, data } = useQuery(query);

  const options = data?.getReportProducts;

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!options) {
    return <CircularProgress />;
  }

  const urlParams = new URLSearchParams();

  if (state.from) {
    urlParams.set('from', state.from);
  }
  if (state.to) {
    urlParams.set('to', state.to);
  }
  if (state.productId) {
    urlParams.set('product', state.productId);
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Marknadsrapport - Uppföljning och nyckeltal
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Filtrera i grafen för ARPU, MRR och intäkter genom att välja
            graf-datum och/eller produkt.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Filtrera i tabellen genom att välja Produkt, Från- och/eller Till i
            datumväljarna.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Två CSV-filer finns att ladda ner.
          </Typography>
          <Typography variant="body2" component="p">
            (1) Statistik för intäkter, MRR, och ARPU för alla produkter.
          </Typography>
          <Typography variant="body2" component="p">
            (2) Statistiken som visas ut i tabellen.
          </Typography>
        </CardContent>
      </Card>
      <Box display="flex">
        <Box
          css={({ theme }) => ({
            marginTop: theme.spacing(3),
            marginLeft: 'auto',
            marginRight: 'auto',
          })}
        >
          <DialogTerminology />
        </Box>
      </Box>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.productTextField}
            label="Produkt"
            variant="outlined"
            select
            value={state.productId}
            onChange={e => handleChange('productId', e.target.value)}
          >
            <MenuItem value={''}>
              <em>Alla</em>
            </MenuItem>
            {options.map(product => (
              <MenuItem key={product.name} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={() => ({
                textTransform: 'none',
              })}
              onClick={() => clearState()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          inputVariant="outlined"
          openTo="year"
          views={['year']}
          label="Graf-datum"
          value={state.graphYear}
          onChange={date => handleChange('graphYear', date)}
        />
      </MuiPickersUtilsProvider>
      <Box>
        <IncomeChart
          productId={state.productId}
          year={state.graphYear}
          options={options}
        />
        <Box display="flex" className={classes.box}>
          <Box ml="auto">
            <Button
              color="primary"
              variant="contained"
              startIcon={<GetApp />}
              component="a"
              target="_blank"
              href={`/api/export_graph_keyfigures`}
              rel="noreferrer"
            >
              MRR och ARPU
            </Button>
          </Box>
        </Box>
        <KeyFigureListResults state={state} />
      </Box>
      <Box display="flex" className={classes.box}>
        <Box ml="auto">
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetApp />}
            component="a"
            target="_blank"
            href={`/api/export_keyfigures?${urlParams.toString()}`}
            rel="noreferrer"
          >
            Nyckeltal
          </Button>
        </Box>
      </Box>
    </>
  );
}

KeyFigureList.propTypes = {
  state: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    graphYear: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
};
