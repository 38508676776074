import { useQuery } from '@apollo/client';
import {
  AppBar,
  Badge,
  Box,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ChevronLeft, ExitToApp, Menu } from '@material-ui/icons';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Breadcrumbs } from '~/components/Breadcrumbs';
import ErrorMessage from '~/components/ErrorMessage';
import useAuthUser from '~/hooks/useAuthUser';

import query from './_GetBadgeCounts.gql';
import menus from './menu';

const drawerWidth = 240;

export default function Layout({ children }) {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const [open, setOpen] = useState(mdUp);
  const { pathname } = useLocation();
  const [nestedListExpanded, setNestedListExpanded] = useState(false);

  const handleNestedListExpanded = () => {
    setNestedListExpanded(!nestedListExpanded);
  };

  const openDrawer = useCallback(() => setOpen(true), []);
  const closeDrawer = useCallback(() => setOpen(false), []);

  const { error, data } = useQuery(query);

  const { isAdmin } = useAuthUser();

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!data) {
    return (
      <Box
        display="flex"
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex">
      <AppBar
        open={open}
        position="fixed"
        css={({ open, theme: { breakpoints, transitions } }) => ({
          transition: transitions.create(['margin', 'width'], {
            easing: transitions.easing[open ? 'easeOut' : 'sharp'],
            duration:
              transitions.duration[open ? 'leavingScreen' : 'enteringScreen'],
          }),
          width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
          marginLeft: open ? `${drawerWidth}px` : 0,

          [breakpoints.up('lg')]: {
            marginLeft: `${drawerWidth}px`,
          },
        })}
      >
        <Toolbar>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={openDrawer}
              edge="start"
              open={open}
              css={({ theme, open }) => ({
                marginRight: theme.spacing(2),
                display: open ? 'none' : 'inline-flex',
              })}
            >
              <Menu />
            </IconButton>
          </Hidden>
          <Typography variant="h6" noWrap>
            TR-Hub Admin
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={lgUp ? 'permanent' : mdUp ? 'persistent' : 'temporary'}
        anchor="left"
        open={open}
        onClose={closeDrawer}
      >
        <Box width={drawerWidth}>
          <Box
            css={({ theme }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: theme.spacing(0, 1),
              justifyContent: 'flex-end',
              ...theme.mixins.toolbar,
            })}
          >
            <Hidden lgUp>
              <IconButton onClick={closeDrawer}>
                <ChevronLeft />
              </IconButton>
            </Hidden>
          </Box>
          <Divider />
          <List>
            {menus.primary.map(item => (
              <ListItem
                button
                key={item.path}
                component={Link}
                to={item.path}
                selected={pathname.includes(item.path)}
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
          {isAdmin && (
            <>
              <Divider />
              <List>
                {menus.secondary.map((item, index) => (
                  <div key={index}>
                    {item.text === 'Rapporter' ? (
                      <>
                        <ListItem button onClick={handleNestedListExpanded}>
                          <ListItemIcon>
                            <item.icon />
                          </ListItemIcon>
                          <ListItemText primary={item.text} />
                          {nestedListExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse
                          in={nestedListExpanded}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {menus.nestedReports.map(item => (
                              <ListItem
                                key={item.path}
                                button
                                component={Link}
                                to={item.path}
                                css={({ theme }) => ({
                                  paddingLeft: theme.spacing(4),
                                })}
                              >
                                <ListItemIcon>
                                  <item.icon />
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    ) : (
                      <ListItem button component={Link} to={item.path}>
                        <ListItemIcon>
                          <item.icon />
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                        {item.text === 'Attestering' && (
                          <Badge
                            badgeContent={data?.getOpenRefundsCount.total}
                            color="error"
                          />
                        )}
                        {item.text === 'Ärenden' && (
                          <Badge
                            badgeContent={data?.getNewIssuesCount.total}
                            color="error"
                          />
                        )}
                      </ListItem>
                    )}
                  </div>
                ))}
              </List>
            </>
          )}
          <Divider />
          <List>
            <ListItem
              button
              component="a"
              href={`${
                process.env.REACT_APP_AUTH_URL
              }/logout?ReturnUrl=${encodeURI(window.location.href)}`}
            >
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Logga ut" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <main
        open={open}
        css={({ open, theme: { breakpoints, transitions } }) => ({
          flexGrow: 1,
          maxWidth: '100%',
          height: '100vh',

          [breakpoints.up('md')]: {
            marginLeft: open ? `${drawerWidth}px` : 0,
            maxWidth: open ? `calc(100% - ${drawerWidth}px)` : '100%',
            transition: transitions.create('margin', {
              easing: transitions.easing[open ? 'easeOut' : 'sharp'],
              duration:
                transitions.duration[open ? 'leavingScreen' : 'enteringScreen'],
            }),
          },
          [breakpoints.up('lg')]: {
            marginLeft: `${drawerWidth}px`,
            maxWidth: '100%',
          },
        })}
      >
        <Container
          maxWidth="lg"
          css={({ theme }) => ({
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
          })}
        >
          <Box
            css={({ theme }) => ({
              ...theme.mixins.toolbar,
            })}
          />
          <Breadcrumbs />
          {children}
        </Container>
      </main>
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
