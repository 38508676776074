import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Campaign from './Campaign';
import CampaignAdd from './CampaignAdd';
import CampaignList from './CampaignList';

export default function Campaigns() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Kampanjer" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <CampaignList />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/add`} exact>
          <CampaignAdd />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:campaign`}>
          <Campaign />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
