import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { useCallback, useState } from 'react';

import useQueryParams from '~/hooks/useQueryParams';

import UserListResults from './UserListResult';

const initialValues = {
  search: '',
  onlyCollectionPayee: false,
};

export default function UserList() {
  const [selectedRows, setSelectedRows] = useState([]);

  const { state, params, handleChange } = useQueryParams(initialValues);

  const handleSelectRows = useCallback(values => {
    setSelectedRows(values);
  }, []);

  return (
    <>
      <Box mb={2} display="flex" alignItems="flex-end">
        <TextField
          label="Sök"
          variant="outlined"
          value={state.search}
          onChange={e => handleChange('search', e.target.value)}
        />
        <Box ml={2}>
          <FormControlLabel
            label="Endast samlingsbetalare"
            control={
              <Checkbox
                checked={state.onlyCollectionPayee}
                onChange={_ =>
                  handleChange(
                    'onlyCollectionPayee',
                    !state.onlyCollectionPayee,
                  )
                }
              />
            }
          />
        </Box>
        <Box ml="auto">
          <Button
            color="primary"
            disabled={selectedRows.length < 2}
            variant="contained"
          >
            Sammanfoga användare
          </Button>
        </Box>
      </Box>
      <UserListResults
        params={params}
        selectedRows={selectedRows}
        handleSelectRows={handleSelectRows}
      />
    </>
  );
}
