import { Route } from 'react-router-dom';

import ErrorBoundary from '~/components/ErrorBoundary';

const ErrorBoundRoute = props => (
  <ErrorBoundary>
    <Route {...props} />
  </ErrorBoundary>
);

export default ErrorBoundRoute;
