export const Types = {
  productTypes: {
    book: 'Bok',
    ticket: 'Klippkort',
    subscription: 'Prenumeration',
    license: 'License',
    bettip: 'Speltips',
    bettip_template: 'Speltipsmall',
  },

  lineItemTypes: {
    product: 'Produkt',
    commerce_coupon: 'Kampanj',
    ticket: 'Klippkort',
    fee: 'Avgift',
    refund: 'Återbetalning',
    shipping: 'Frakt',
    deduction: 'Avdrag',
    credited: 'Krediterad',
  },

  paymentMethods: {
    card: 'Kreditkort',
    swish: 'Swish',
    invoice: 'Faktura',
    autogiro: 'Autogiro',
    notification: 'Avisering',
    // free denotes if admin has granted a free subscription to the user
    // this is used prevent recurring service to create invoices
    free: 'Gratis',
  },

  get paymentIntervals() {
    const MONTHLY = 1;
    const QUARTELY = 3;
    const HALF_YEARLY = 6;
    const YEARLY = 12;

    return {
      [MONTHLY]: 'Månadsvis',
      [QUARTELY]: 'Kvartal',
      [HALF_YEARLY]: 'Halvårsvis',
      [YEARLY]: 'Årsvis',
    };
  },

  billectaPaymentMethods: {
    BankGiro: 'BankGiro',
    PlusGiro: 'PlusGiro',
    BankAccount: 'Bankkonto',
  },

  sites: {
    jokersystemet: 'Jokersystemet',
    overodds: 'Överodds',
    spelvarde: 'Spelvärde',
    storavinster: 'Stora vinster',
    travfakta: 'Travfakta',
    travronden: 'Travronden',
    travronden_spel: 'Travronden Spel',
    travogat: 'Travögat',
  },

  dateTypes: {
    daily: 'Dagligen',
    weekly: 'Veckovis',
    monthly: 'Månadsvis',
    yearly: 'Årsvis',
  },

  betTypes: ['DD', 'LD', 'GS75', 'V4', 'V64', 'V65', 'V75', 'V86', 'EXTRAV75'],

  VATs: [0, 6, 12, 25],

  /**
   * active field used for userPages to define active reasons, should be concealed for user when false
   * @param site used as filter when cancelling subscription of product type/site
   */
  getUnsubscribeReasons({ site = null, activeOnly = false } = {}) {
    const expectationReasons = {
      jokersystemet: {
        text: 'Jokersystemet uppfyller inte mina förväntningar',
        active: true,
      },
      overodds: {
        text: 'Överodds uppfyller inte mina förväntningar',
        active: true,
      },
      spelvarde: {
        text: 'Spelvärde uppfyller inte mina förväntningar',
        active: true,
      },
      storavinster: {
        text: 'Stora Vinster uppfyller inte mina förväntningar',
        active: true,
      },
      travfakta: {
        text: 'Travfakta uppfyller inte mina förväntningar',
        active: true,
      },
      travronden: {
        text: 'Travronden uppfyller inte mina förväntningar',
        active: true,
      },
      travronden_spel: {
        text: 'Travronden Spel uppfyller inte mina förväntningar',
        active: true,
      },
      travogat: {
        text: 'Travögat uppfyller inte mina förväntningar',
        active: true,
      },
    };

    const otherReasons = {
      limitedTime: {
        text: 'Jag har inte tid att nyttja min prenumeration',
        active: true,
      },
      tooExpensive: { text: 'Prenumerationen är för dyr', active: true },
      passedDueDate: { text: 'Passerat förfallodatumet', active: false },
      other: { text: 'Annat', active: true },
    };

    // Return only active unsubscribeReasons
    if (activeOnly) {
      const entries = Object.entries({
        ...expectationReasons,
        ...otherReasons,
      }).filter(([_, value]) => value.active === true);
      return Object.fromEntries(entries);
    }

    // Return otherReasons + expectationReason of site
    if (site) {
      return { [site]: expectationReasons[site], ...otherReasons };
    }

    return {
      ...expectationReasons,
      ...otherReasons,
    };
  },

  transactionStatus: {
    pending: 'Inväntar',
    complete: 'Slutförd',
    canceled: 'Avbruten',
    failed: 'Misslyckad',
    hard_decline: 'Ogiltigt kort',
  },

  distributableProducts: {
    'MC-TRT': 'Travronden',
    'MC-V75': 'Banprogram V75',
    'MC-V86': 'Banprogram V86',
    'MC-TVI': 'Vinternumret',
    'MC-EIN': 'Einars',
  },

  orderStatus: {
    complete: 'Slutförd',
    draft: 'Utkast',
    closed: 'Stängd',
  },

  paymentStatus: {
    init: 'Initierad',
    awaiting: 'Inväntar betalning',
    paid: 'Betald',
    failed: 'Misslyckad',
    credited: 'Manuellt krediterad',
  },

  complaintCodes: {
    1: 'Tidningen kom inte',
    2: 'Tidningen var skadad',
    //3: 'Meddelande (ej reklamation)',
    4: 'Tidningen kom sent',
    5: 'Tidningen var ej nedstoppad i brevlådan',
    //6: 'Abonnemang saknas, dela ej ut tidningen',
    7: 'Fel uppgifter på tidningen',
  },

  // Additional complaintCodes for damaged magazine (complaintCode: 2)
  complaintCodesByDamaged: {
    21: 'Våt',
    22: 'Trasig',
    23: 'Läst',
    24: 'Bilaga saknas',
  },

  userRoles: {
    admin: 'Admin',
    attestant: 'Attestant',
    'andelsspel:admin': 'Andelsspel: Admin',
    'andelsspel:contributor': 'Andelsspel: Skribent',
    'andelsspel:editor': 'Andelsspel: Redaktör',
    'breedly:admin': 'Breedly: Admin',
    'customer-service': 'Kundtjänst',
    'galopptips:admin': 'Galopptips: Admin',
    'galopptips:contributor': 'Galopptips: Skribent',
    'galopptips:editor': 'Galopptips: Redaktör',
    'joker_sport:admin': 'Joker Sport: Admin',
    'joker_sport:contributor': 'Joker Sport: Skribent',
    'joker_sport:editor': 'Joker Sport: Redaktör',
    'jokersystemet:admin': 'Jokersystemet: Admin',
    'jokersystemet:contributor': 'Jokersystemet: Skribent',
    'jokersystemet:editor': 'Jokersystemet: Redaktör',
    'jokersystemet:freerole-premium': 'Jokersystemet: Gratis premium',
    'jokersystemet:freerole': 'Jokersystemet: Gratis',
    'overodds:admin': 'Överodds: Admin',
    'overodds:contributor': 'Överodds: Skribent',
    'overodds:editor': 'Överodds: Redaktör',
    'spelvarde:admin': 'Spelvärde: Admin',
    'storavinster:admin': 'Storavinster: Admin',
    'trais:admin': 'TRAIS: Admin',
    'trais:contributor': 'TRAIS: Skribent',
    'trais:editor': 'TRAIS: Redaktör',
    'travfakta:admin': 'Travfakta: Admin',
    'travogat:admin': 'Travögat: Admin',
    'travronden_spel:admin': 'Travronden Spel: Admin',
    'travronden_spel:contributor': 'Travronden Spel: Skribent',
    'travronden_spel:editor': 'Travronden Spel: Redaktör',
    'travronden_spel:freerole': 'Travronden Spel: Gratis',
    'travronden:admin': 'Travronden: Admin',
    'travronden:contributor': 'Travronden: Skribent',
    'travronden:editor': 'Travronden: Redaktör',
    'travronden:freerole': 'Travronden: Gratis',
    'trhub:admin': 'TR-hub: Admin',
    'trhub:contributor': 'TR-hub: Skribent',
    'trhub:editor': 'TR-hub: Redaktör',
  },

  issueCategories: {
    payment_failure: 'Betalningsmottagning',
    recurring_failure: 'Återkommande betalningar',
    trais_failure: 'TRAIS',
    generate_report_failure: 'Rapporter',
    closing_notification_failutre: 'Borttagning av avier',
    attesting_autogiro_failure: 'Attestera autogiro fakturor',
    contact_form_failure: 'Kontaktformulär',
    complaint_info: 'Reklamation',
  },

  campaignTypes: {
    fixedPrice: 'Fast pris',
    monthlyDiscount: 'Avdrag per månad',
  },

  issueSeverities: {
    INFO: 'Info',
    ERROR: 'Fel',
    CRITICAL: 'Kritiskt',
  },

  issueStatus: { NEW: 'Ny', ACKNOWLEDGED: 'Pågående', CLOSED: 'Stängd' },

  /**
   * Projects are used by the economy department to assign products to different accounts.
   * It is needed among products that is to be synced to Billecta.
   */
  allProjects: [
    100, 101, 102, 111, 200, 202, 206, 207, 208, 209, 300, 301, 302, 304, 305,
    306, 307, 308, 309, 900, 901, 902, 903, 904,
  ],

  /**
   * costCenters are used by the economy department to assign products to different accounts.
   * It is needed among products that is to be synced to Billecta.
   */
  allCostCenters: [10, 20, 30, 90],

  /**
   * SKUs of products which grants access to prenly-products
   */
  prenlySKUs: [
    'TR-SPORT-ETIDNING',
    'TR-SPEL-ETIDNING',
    'TR-HELG-ETIDNING',
    'TR-MAGASIN',
  ],

  /**
   * SKUs of products which grants premium-access
   */
  premiumSKUs: ['TR-SPORT', 'TR-SPEL'],

  cardOrigins: {
    checkout: 'Checkout',
    drupal: 'Drupal',
  },

  termsStatus: {
    draft: 'Utkast',
    published: 'Publicerad',
    unpublished: 'Avpublicerad',
  },
};
