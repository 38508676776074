import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Terms from './Terms';
import TermsAdd from './TermsAdd';
import TermsList from './TermsList';

export default function Users() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Allmänna villkor" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <TermsList />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/add`}>
          <TermsAdd />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:id`}>
          <Terms />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
