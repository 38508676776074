import 'react-markdown-editor-lite/lib/index.css';

import MarkdownIt from 'markdown-it';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import MdEditor, { Plugins } from 'react-markdown-editor-lite';

const mdParser = new MarkdownIt({ breaks: true, linkify: true });

// Fullscreen is not usable because the editor renders below the top
// AppBar and left Drawer
MdEditor.unuse(Plugins.FullScreen);

const TextEditor = ({ value, onChange }) => {
  const handleEditorChange = useCallback(
    ({ text }) => {
      onChange(text);
    },
    [onChange],
  );

  return (
    <MdEditor
      style={{ height: '800px' }}
      value={value}
      renderHTML={text => mdParser.render(text)}
      onChange={handleEditorChange}
    />
  );
};

TextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextEditor;
