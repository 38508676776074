import 'date-fns';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';

export default function DialogTerminology() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        Terminologi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Nyckeltal terminologi</DialogTitle>
        <DialogContent>
          <Typography variant="body2" component="p">
            Nya användare: Användare som inte har varit prenumeranter på denna
            produkt tidigare.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Återaktiverad avbruten: Användare som har valt att fortsätta sin
            prenumeration innan slutdatumet har passerat.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Återaktiverad passiv: Användare som tidigare har varit prenumerant
            på produkten, och har påbörjat en ny prenumeration av samma produkt
            detta datumet.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Avbrutna användare: Användare som har satt ett kommande slutdatum,
            men har access för tillfället.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Avslutade användare: Användare som avslutade prenumerationen detta
            datumet.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Aktiva användare: Användare som är tillsvidare prenumeranter, inget
            slutdatum satt.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Inaktiva användare: Användare som har varit prenumeranter tidigare.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Totala användare: Aktiva + Avbrutna
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Total intäkt = Total intäkt för alla kunder i kategorierna
            tillsvidare + avbrutna.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            MRR = Total intäkt för alla kunder i kategorin tillsvidare.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            ARPU = Total intäkt för alla kunder i kategorierna tillsvidare +
            avbrutna/antalet kunder som är tillsvidare + avbrutna.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Stäng
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
