import { Box } from '@material-ui/core';
import { format as formatLib } from 'date-fns';
import PropTypes from 'prop-types';

/**
 * Displays a date both in human and machine readable form.
 *
 * Formatting docs: https://date-fns.org/docs/format
 */
const Time = ({ date, format, ...props }) => (
  <Box
    component="time"
    dateTime={date.toISOString()}
    whiteSpace="noWrap"
    {...props}
  >
    {formatLib(date, format)}
  </Box>
);

Time.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  format: PropTypes.string,
};

Time.defaultProps = {
  format: 'yyyy-MM-dd',
};

export default Time;
