import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import getNewIssuesCount from '~/components/Layout/_GetBadgeCounts.gql';
import { issuePropType } from '~/propTypes';

import mutation from './_EditIssue.gql';
import IssueForm from './IssueForm';

export default function IssueEdit({ issueData }) {
  const { issue } = useParams();

  const [action] = useMutation(mutation, {
    refetchQueries: [{ query: getNewIssuesCount }],
  });

  const history = useHistory();

  const {
    dispatcher,
    category,
    description,
    severity,
    status,
    assignee,
    created,
  } = issueData.getIssue;

  return (
    <>
      <Breadcrumb name="Redigera" />
      <IssueForm
        initialValues={{
          dispatcher,
          category,
          description,
          severity,
          status,
          assignee: assignee || '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              id: issue,
              input: { ...values, created },
            },
          });

          setSubmitting(false);

          if (response) {
            history.push(`/issues/${issue}`);
          }
        }}
      />
    </>
  );
}

IssueEdit.propTypes = {
  issueData: PropTypes.shape({
    getIssue: issuePropType,
  }).isRequired,
};
