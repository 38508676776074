import { Box, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import { orderPropType } from '~/propTypes';

import OrderInformation from './OrderInformation';
import OrderLineItems from './OrderLineItems';
import OrderPayments from './OrderPayments';
import OrderTransactions from './OrderTransactions';

export default function OrderDetail({ data }) {
  const order = data.getOrder;
  const { pathname } = useLocation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <OrderInformation order={order} />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrderLineItems order={order} />
        {order.paymentStatus === 'paid' &&
          ['card', 'notification', 'autogiro'].includes(
            order.paymentMethod,
          ) && (
            <Box mt={2}>
              <Button
                component={NavLink}
                to={`${pathname}/refund`}
                variant="contained"
                color="secondary"
              >
                Registrera återbetalning
              </Button>
            </Box>
          )}
      </Grid>
      <Grid item xs={12} md={12}>
        <OrderTransactions order={order} />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrderPayments order={order} />
      </Grid>
    </Grid>
  );
}

OrderDetail.propTypes = {
  data: PropTypes.shape({
    getOrder: orderPropType.isRequired,
  }),
};
