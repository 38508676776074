import { useApolloClient, useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';

import addTermsMutation from './_AddTerms.gql';
import TermsForm from './TermsForm';

export default function TermsAdd() {
  const apollo = useApolloClient();
  const [error, setError] = useState('');

  const [action] = useMutation(addTermsMutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });
  const history = useHistory();

  return (
    <>
      <Breadcrumb name="Lägg till ny" />
      {error.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <TermsForm
        addTerms
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const response = await action({
              variables: {
                input: values,
              },
            });

            setSubmitting(false);

            if (response) {
              history.push(`/terms/${response.data.addTerms.id}`);
            }
          } catch (error_) {
            setError(error_.message);
          }
        }}
      />
    </>
  );
}
