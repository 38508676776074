import { MenuItem, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const Select = ({ onChange, value, label, options }) => (
  <TextField
    label={label}
    variant="outlined"
    value={value}
    select
    onChange={onChange}
  >
    {options.map(option => (
      <MenuItem key={option.value} value={option.value}>
        <em>{option.label}</em>
      </MenuItem>
    ))}
  </TextField>
);

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

export default Select;
