import { Box } from '@material-ui/core';

import AttestListResult from './AttestListResult';

export default function AttestList() {
  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box display="grid" gridAutoFlow="column" gridColumnGap="10px">
          {/*todo filter fields*/}
        </Box>
      </Box>
      <Box>
        <AttestListResult />
      </Box>
    </>
  );
}
