import { useCallback, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Issue from './Issue';
import IssueAdd from './IssueAdd';
import IssueList from './IssueList';

export default function Issues() {
  const { path } = useRouteMatch();

  const initialState = {
    searchValues: '',
    severity: '',
    category: '',
    assignee: '',
    status: 'NEW',
  };

  const [state, setState] = useState(initialState);

  // Handlechange for search & filter component
  const handleChange = useCallback((type, value) => {
    setState(prev => ({ ...prev, [type]: value }));
  }, []);

  const clearState = () => {
    setState({ ...initialState, status: '' });
  };

  return (
    <>
      <Breadcrumb name="Ärenden" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <IssueList
            state={state}
            handleChange={handleChange}
            clearState={clearState}
          />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/add`} exact>
          <IssueAdd />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:issue`}>
          <Issue />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
