import { Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

import ErrorMessage from '~/components/ErrorMessage';
import useQueryParams from '~/hooks/useQueryParams';

import IncomeListResults from './IncomeListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  from: '',
  to: '',
};

export default function IncomeList() {
  const classes = useStyles();

  const { params, state, clear, handleChange } = useQueryParams(initialValues);

  const [error] = useState({ message: '' });

  const urlParams = new URLSearchParams();

  if (state.from) {
    urlParams.set('from', state.from);
  }
  if (state.to) {
    urlParams.set('to', state.to);
  }

  if (error.message) {
    return <ErrorMessage error={error} />;
  }
  return (
    <>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={() => clear()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <IncomeListResults params={params} />
      </Box>
    </>
  );
}
