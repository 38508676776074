import { Box, Button, LinearProgress, MenuItem } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { Types } from 'trhub-utils';
import * as Yup from 'yup';

import DateInputField from '~/components/DateInputField';
import { editionType } from '~/propTypes';
import toOptions from '~/utils/toOptions';

const validationSchema = Yup.object().shape({
  editionLabel: Yup.string().required('Ett utgivningsnummer måste anges.'),
  sku: Yup.string().required('En SKU måste anges.'),
});

export default function EditionForm({ initialValues, onSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ submitForm, isSubmitting, setFieldValue, values }) => (
        <Form
          css={({ theme }) => ({
            '> * + *': {
              marginTop: theme.spacing(2),
            },
            '> *': {
              width: '100%',
            },
            '> .MuiFormControl-root': {
              backgroundColor: theme.palette.background.default,
            },
          })}
        >
          <Field
            component={TextField}
            name="editionLabel"
            label="Utgivningsnummer"
            variant="outlined"
          />
          <Field
            component={TextField}
            select
            name="sku"
            label="Produkt"
            variant="outlined"
          >
            {toOptions(Types.distributableProducts).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          <DateInputField
            name="productionDate"
            label="Produktionsdatum"
            value={values.productionDate}
            minDate={new Date('2010-01-01')}
            clearIconEnabled={values.productionDate !== ''}
            variant="outlined"
            setFieldValue={setFieldValue}
          />
          <DateInputField
            name="lastOrderDate"
            label="Sista beställningsdatum"
            value={values.lastOrderDate}
            minDate={new Date('2010-01-01')}
            clearIconEnabled={values.lastOrderDate !== ''}
            variant="outlined"
            setFieldValue={setFieldValue}
          />
          <DateInputField
            name="distributionDate"
            label="Uttagsdatum"
            value={values.distributionDate}
            minDate={new Date('2010-01-01')}
            clearIconEnabled={values.distributionDate !== ''}
            variant="outlined"
            setFieldValue={setFieldValue}
          />
          <DateInputField
            name="deliveryDate"
            label="Utdelningsdatum"
            required
            value={values.deliveryDate}
            minDate={new Date('2010-01-01')}
            clearIconEnabled={values.deliveryDate !== ''}
            variant="outlined"
            setFieldValue={setFieldValue}
          />

          {isSubmitting && <LinearProgress />}
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Spara
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

EditionForm.propTypes = {
  initialValues: editionType,
  addEdition: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
