import { useCallback, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import KeyFigureList from './KeyFigureList';

export default function KeyFigures() {
  const { path } = useRouteMatch();

  const initialState = {
    productId: '',
    from: '',
    to: '',
    graphYear: new Date(),
  };

  const [state, setState] = useState(initialState);

  // Handlechange for search & filter component
  const handleChange = useCallback((type, value) => {
    setState(prev => ({
      ...prev,
      [type]: value,
    }));
  }, []);

  const clearState = () => {
    setState({ ...initialState });
  };

  return (
    <>
      <Breadcrumb name="Uppföljning och nyckeltal" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <KeyFigureList
            state={state}
            handleChange={handleChange}
            clearState={clearState}
          />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
