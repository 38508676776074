import { useQuery } from '@apollo/client';
import {
  Chip,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Types } from 'trhub-utils';

import ErrorMessage from '~/components/ErrorMessage';
import NoUserData from '~/components/NoUserData';
import PaymentMethodIcon from '~/components/PaymentMethodIcon';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';
import formatPrice from '~/utils/formatPrice';

import query from './_GetOrders.gql';

const OrderList = ({ filter }) => {
  const { page, pageSize, updatePage, updateRowSize } = usePagination(10);

  const { error, loading, data } = useQuery(query, {
    variables: { filter, page: page, limit: pageSize },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const headers = ['', 'Datum', 'Order', 'Pris', 'Status', 'Betalning'];

  const res = data?.orders;

  const orderStatusTypes = Types.orderStatus;
  const paymentStatusTypes = Types.paymentStatus;

  return (
    <TableList
      title="Ordrar"
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
    >
      {res?.items?.map(order => (
        <TableRow key={order.id}>
          <TableCell>
            <PaymentMethodIcon paymentMethod={order.paymentMethod} />
          </TableCell>
          <TableCell>
            <Time date={new Date(order.created)} />
          </TableCell>
          <TableCell>
            <Link component={RouterLink} to={`/order/${order.id}`}>
              {order.label}
            </Link>
          </TableCell>
          <TableCell>{formatPrice(order.totalPrice)}</TableCell>
          <TableCell>
            <Chip
              color="primary"
              label={orderStatusTypes[order.orderStatus]}
              size="small"
            />
          </TableCell>
          <TableCell>
            <Chip
              color="primary"
              label={
                order.paymentStatus
                  ? paymentStatusTypes[order.paymentStatus]
                  : 'Info saknas'
              }
              size="small"
            />
          </TableCell>
        </TableRow>
      ))}

      {res?.items.length === 0 && (
        <TableRow>
          <TableCell>
            <NoUserData text="Det finns inga ordrar" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
};

OrderList.propTypes = {
  filter: PropTypes.shape({
    user: PropTypes.string,
    subscription: PropTypes.string,
  }),
};

export default OrderList;
