import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import Bugsnag from '~/utils/bugsnag';

FallbackComponent.propTypes = {
  error: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
};

function FallbackComponent({ error, info }) {
  return <ErrorMessage error={new Error(`${error}\n${info.componentStack}`)} />;
}

const ErrorBoundary = ({ children }) => {
  const BugsnagErrorBoundary = Bugsnag.getPlugin('react');
  return (
    <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </BugsnagErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

ErrorBoundary.displayName = 'AppErrorBoundary';

export default ErrorBoundary;
