import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';

export const NumberField = ({ name, label, value, select, children }) => (
  <Field
    component={TextField}
    select={select}
    type="text"
    inputMode="numeric"
    pattern="[0-9]*"
    variant="outlined"
    onWheel={event => event.target.blur()}
    name={name}
    label={label}
    value={value}
  >
    {children}
  </Field>
);

NumberField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  select: PropTypes.bool,
  children: PropTypes.node,
};
