import { useQuery } from '@apollo/client';
import {
  Button,
  Chip,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink, NavLink, useLocation } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import NoUserData from '~/components/NoUserData';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetSubscriptionsByUser.gql';

export default function Subscriptions({ user, collectionPayee }) {
  const { pathname } = useLocation();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(10);

  const variables = { page: page, limit: pageSize };
  if (collectionPayee) {
    variables.payee = user;
  } else {
    variables.user = user;
  }
  const { error, loading, data } = useQuery(query, { variables });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  let headers = ['Startdatum', 'Produkt', 'Status'];
  if (collectionPayee) {
    headers = ['Startdatum', 'Produkt', 'Mottagare', 'Status'];
  }

  const res = data?.getSubscriptionsByUser;

  return (
    <TableList
      title={
        collectionPayee ? 'Samlingsbetalda prenumerationer' : 'Prenumerationer'
      }
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
      button={
        collectionPayee ? (
          <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to={`${pathname}/subscription/add`}
          >
            Lägg till prenumeration
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to={`${pathname}/subscription`}
          >
            Ge gratisprenumeration
          </Button>
        )
      }
    >
      {res?.subscriptions.length > 0 ? (
        res.subscriptions.map(subscription => (
          <TableRow key={subscription.id}>
            <TableCell>
              <Time date={new Date(subscription.startDate)} />
            </TableCell>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/subscriptions/${subscription.id}`}
              >
                {subscription.product.name}
              </Link>
            </TableCell>
            {collectionPayee ? (
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/users/${subscription.user.id}`}
                >
                  {subscription.user.label}
                </Link>
              </TableCell>
            ) : (
              ''
            )}
            <TableCell>
              <Chip
                color="primary"
                label={subscription.isActive ? 'Aktiv' : 'Inaktiv'}
                size="small"
              />
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell>
            <NoUserData text="Den här användaren har inga prenumerationer" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

Subscriptions.propTypes = {
  user: PropTypes.string.isRequired,
  collectionPayee: PropTypes.bool,
};
