import { Box, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';

import useAuthUser from '~/hooks/useAuthUser';
import useQueryParams from '~/hooks/useQueryParams';

import CampaignListResult from './CampaignListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
  },
});

const initialValues = { search: '' };

export default function CampaignList() {
  const classes = useStyles();

  const { pathname } = useLocation();

  const { isAdmin } = useAuthUser();

  const { state, params, handleChange } = useQueryParams(initialValues);

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box
          maxWidth="70%"
          display="grid"
          gridAutoFlow="column"
          gridColumnGap="10px"
        >
          <TextField
            className={classes.textField}
            label="Sök"
            variant="outlined"
            value={state.search}
            onChange={e => handleChange('search', e.target.value)}
          />
        </Box>
        {isAdmin && (
          <Button
            component={Link}
            to={`${pathname}/add`}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Lägg till
          </Button>
        )}
      </Box>
      <Box>
        <CampaignListResult params={params} />
      </Box>
    </>
  );
}
