//TODO: Uncomment for future issue (Show incomechart for each month)
import { useQuery } from '@apollo/client';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import ErrorMessage from '~/components/ErrorMessage';
import Time from '~/components/Time';

import query from './_GetGraphKeyFiguresStats.gql';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '240px',
  },
}));
export default function IncomeChart({ productId, year, options }) {
  const classes = useStyles();

  const { error, data } = useQuery(query, {
    variables: {
      productId: productId,
      year: year,
    },
  });

  const product = options.find(prod => prod.id === productId);

  const res = data?.getGraphKeyFiguresStats;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  return (
    <Paper className={classes.paper}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Totalintänkter, MRR och ARPU för{' '}
        {productId ? product.name : 'alla produkter'} år{' '}
        <Time date={new Date(year)} format="yyyy" />
      </Typography>

      <ResponsiveContainer>
        <LineChart data={res}>
          <Line
            type="monotone"
            dataKey="totalIncome"
            name="Intäkter"
            stroke="#8884d8"
          />
          <Line type="monotone" dataKey="mrr" name="MRR" stroke="#1EB721" />
          <Line type="monotone" dataKey="arpu" name="ARPU" stroke="#F55B39" />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
}

IncomeChart.propTypes = {
  productId: PropTypes.string.isRequired,
  year: PropTypes.instanceOf(Date).isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
};
