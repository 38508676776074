import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';
import { issuePropType } from '~/propTypes';

export default function IssueDetail({ issueData }) {
  const { pathname } = useLocation();

  const {
    dispatcher,
    category,
    description,
    severity,
    status,
    created,
    assignee,
    items,
  } = issueData.getIssue;

  const tableRows = [
    ['Avsändare', dispatcher],
    ['Kategori', Types.issueCategories[category]],
    ['Beskrivning', description],
    ['Allvarsgrad', Types.issueSeverities[severity]],
    ['Status', Types.issueStatus[status]],
    [
      'Skapad',
      <Time key={created} date={new Date(created)} format="yyyy-MM-dd HH:mm" />,
    ],
    ['Mottagare', assignee],
  ];

  if (items?.length > 0) {
    items.forEach((item, index) =>
      tableRows.push([`Logg (${index + 1})`, item]),
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TablePaper>
          <TableContainer>
            <Table>
              <TableBody>
                {tableRows.map(([label, value]) => (
                  <TableRow key={label}>
                    <TableCell component="th" scope="row">
                      {label}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TablePaper>

        <Box mt={2}>
          <Button
            component={NavLink}
            to={`${pathname}/edit`}
            variant="contained"
            color="secondary"
          >
            Redigera
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

IssueDetail.propTypes = {
  issueData: PropTypes.shape({
    getIssue: issuePropType,
  }).isRequired,
};
