import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import { editionType } from '~/propTypes';

import mutation from './_EditEdition.gql';
import EditionForm from './EditionForm';

export default function EditionEdit({ editionData }) {
  const { edition } = useParams();

  const [action] = useMutation(mutation);
  const history = useHistory();

  const {
    sku,
    editionLabel,
    productionDate,
    lastOrderDate,
    distributionDate,
    deliveryDate,
  } = editionData.getEdition;

  return (
    <>
      <Breadcrumb name="Redigera" />
      <EditionForm
        initialValues={{
          sku,
          editionLabel,
          productionDate: productionDate || '',
          lastOrderDate: lastOrderDate || '',
          distributionDate: distributionDate || '',
          deliveryDate,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await action({
            variables: {
              id: edition,
              input: values,
            },
          });
          setSubmitting(false);
          history.push(`/editions/${edition}`);
        }}
      />
    </>
  );
}

EditionEdit.propTypes = {
  editionData: PropTypes.shape({
    getEdition: editionType,
  }).isRequired,
};
