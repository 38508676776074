import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import query from '~/utils/_GetAllProducts.gql';

import UnsubscribeReasonListResult from './UnsubscribeReasonListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  productTextField: {
    maxWidth: 300,
    minWidth: 260,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default function UnsubscribeReasonList({
  state,
  handleChange,
  clearState,
}) {
  const classes = useStyles();

  const { error, data } = useQuery(query, {
    variables: {
      filterProductType: 'subscription',
    },
  });

  const productList = data?.getAllProducts || [];

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!productList) {
    return <CircularProgress />;
  }

  const urlParams = new URLSearchParams();

  if (state.from) {
    urlParams.set('from', state.from);
  }
  if (state.to) {
    urlParams.set('to', state.to);
  }
  if (state.productId) {
    urlParams.set('product', state.productId);
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Uppsägningsrapport - Anledning till uppsägning av prenumeration
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Filtrera i tabellen genom att välja datum och/eller produkt.
          </Typography>
        </CardContent>
      </Card>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.productTextField}
            label="Produkt"
            variant="outlined"
            select
            value={state.productId}
            onChange={e => handleChange('productId', e.target.value)}
          >
            <MenuItem value={''}>
              <em>Alla</em>
            </MenuItem>
            {productList.map(product => (
              <MenuItem key={product.name} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={() => ({
                textTransform: 'none',
              })}
              onClick={() => clearState()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <UnsubscribeReasonListResult state={state} />
    </>
  );
}

UnsubscribeReasonList.propTypes = {
  state: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
};
