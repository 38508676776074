export const sampleUser1 = Object.freeze({
  uid: 1,
  email: 'test@gmail.com',
  firstName: 'Testförnamn',
  lastName: 'Testefternamn',
  customerNumber: 123,
  mobile: '070 123 45 67',
  address: {
    address: 'Testgatan 1',
    postalCode: '123 45',
    city: 'Göteborg',
    country: 'Sverige',
  },
});

export const sampleUser2 = Object.freeze({
  uid: 2,
  email: 'John@gmail.com',
  firstName: 'John',
  lastName: 'Doe',
  customerNumber: 456,
  billectaId: 'b2cf042a-5b67-49e0-b6d6-a3d4c8e6dae5',
  mobile: '070 987 65 43',
  address: {
    address: 'Johngatan 1',
    postalCode: '987 65',
    city: 'Stockholm',
    country: 'Sverige',
  },
});

export const sampleUser3 = Object.freeze({
  uid: 3,
  email: 'NoName@gmail.com',
  customerNumber: 789,
});
