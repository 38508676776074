import { useApolloClient, useMutation } from '@apollo/client';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import { subscriptionPropType } from '~/propTypes';

import mutation from './_ReactivateSubscription.gql';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
  group: {
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1,
  },
}));

export default function SubscriptionReactivate({ subscriptionData }) {
  const classes = useStyles();

  const history = useHistory();

  const apollo = useApolloClient();

  const { subscription } = useParams();

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const { paymentMethod, user } = subscriptionData.getSubscription;

  const handleReactivateSubscription = useCallback(async () => {
    const response = await action({
      variables: {
        id: subscription,
        user: user.id,
      },
    });

    if (response) {
      history.push(`/subscriptions/${subscription}`);
    }
  }, [action, history, subscription, user.id]);

  return (
    <>
      <Breadcrumb name="Återuppta prenumeration" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Återuppta en prenumeration
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Att återuppta en prenumeration innebär att kunden återfår tillgång
            till prenumerationen.
          </Typography>
          {paymentMethod === 'free' && (
            <Typography
              variant="body2"
              component="p"
              css={`
                margin-top: ${p => p.theme.spacing(2)}px;
              `}
            >
              Det går ej att återuppta denna prenumeration, då den gavs ut
              gratis.
            </Typography>
          )}
        </CardContent>
      </Card>
      <Box>
        <Button
          disabled={paymentMethod === 'free'}
          onClick={handleReactivateSubscription}
          variant="contained"
          color="secondary"
        >
          Återrupta prenumeration
        </Button>
      </Box>
    </>
  );
}

SubscriptionReactivate.propTypes = {
  subscriptionData: PropTypes.shape({
    getSubscription: subscriptionPropType,
  }).isRequired,
};
