import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import AttestList from './AttestList';

export default function Editions() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Attestera återbetalningar" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <AttestList />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
