import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Types } from 'trhub-utils';

import DataGrid from '~/components/DataGrid';
import formatPrice from '~/utils/formatPrice';

import query from './_GetCampaigns.gql';

const columns = [
  {
    field: 'name',
    flex: 2,
    headerName: 'Kampanj',
    renderCell: params => (
      <Link component={RouterLink} to={`/campaigns/${params.row.id}`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: 'code',
    flex: 1,
    headerName: 'Rabattkod',
    sortable: false,
    renderCell: params => (
      <Link component={RouterLink} to={`/order/${params.value}`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: 'productsLabel',
    flex: 2,
    headerName: 'Produkter',
    sortable: false,
    renderCell: params => (
      <Link component={RouterLink} to={`/users/${params.value.id}`}>
        {params.value.email}
      </Link>
    ),
  },
  {
    field: 'type',
    flex: 1,
    headerName: 'Typ',
    sortable: false,
    valueGetter: params => Types.campaignTypes?.[params.value] || ' - ',
  },

  {
    field: 'price',
    flex: 1,
    headerName: 'Pris',
    valueFormatter: params => formatPrice(params.value),
  },
];
export default function CampaignListResults({ params }) {
  const { search } = params;

  return (
    <DataGrid
      columns={columns}
      query={query}
      defaultSort="name"
      filter={{
        search,
      }}
    />
  );
}

CampaignListResults.propTypes = {
  params: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
};
