import { useCallback, useMemo } from 'react';

import useQueryParams from './useQueryParams';

export default function usePagination(size = 30) {
  const initialState = useMemo(
    () => ({
      page: 1,
      pageSize: size,
    }),
    [size],
  );

  const {
    state: { page, pageSize },
    handleChange,
  } = useQueryParams(initialState);

  // Change page, start on index 1
  const updatePage = useCallback(
    newPage => {
      handleChange('page', newPage + 1);
    },
    [handleChange],
  );

  // Update amount of elements of current page
  const updateRowSize = useCallback(
    pageSize => {
      handleChange('pageSize', pageSize);
    },
    [handleChange],
  );

  return { page, pageSize, updatePage, updateRowSize };
}
