import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import User from './User';
import UserList from './UserList';

export default function Users() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Användare" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <UserList />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:user`}>
          <User />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
