import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { Types } from 'trhub-utils';

import useAuthUser from '~/hooks/useAuthUser';
import useQueryParams from '~/hooks/useQueryParams';
import toOptions from '~/utils/toOptions';

import OrderListResults from './OrderListResult';

const useStyles = makeStyles({
  textField: {
    maxWidth: 150,
    minWidth: 125,
    marginTop: 10,
  },
});

const initialValues = {
  productSku: '',
  status: '',
  user: '',
  orderId: '',
};

export default function OrderList() {
  const classes = useStyles();

  const { pathname } = useLocation();

  const { isAdmin } = useAuthUser();

  const { params, state, clear, handleChange } = useQueryParams(initialValues);

  const options = {
    status: toOptions(Types.orderStatus),
  };

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box
          maxWidth="100%"
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Sök order-ID"
            variant="outlined"
            value={state.orderId}
            error={Boolean(
              state.orderId && !/^[\dA-Fa-f]{24}$/.test(state.orderId),
            )}
            onChange={e => handleChange('orderId', e.target.value)}
          />

          <TextField
            className={classes.textField}
            label="Status"
            variant="outlined"
            value={state.status}
            select
            onChange={e => handleChange('status', e.target.value)}
          >
            <MenuItem value="">
              <em>Alla</em>
            </MenuItem>
            {options.status.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className={classes.textField}
            label="Användare"
            variant="outlined"
            value={state.user}
            onChange={e => handleChange('user', e.target.value)}
          />

          <TextField
            className={classes.textField}
            label="Produkt SKU"
            variant="outlined"
            value={state.productSku}
            onChange={e => handleChange('productSku', e.target.value)}
          />

          {Object.values(state).some(property => property !== '') && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={() => clear()}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
        {isAdmin && (
          <Button
            component={Link}
            to={`${pathname}/add`}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Lägg till
          </Button>
        )}
      </Box>
      <Box>
        <OrderListResults params={params} />
      </Box>
    </>
  );
}
