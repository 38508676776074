import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function Address({ address }) {
  return address?.address ? (
    <Box>
      {address.careOf?.length > 0 && (
        <>
          {address.careOf}
          <br />
        </>
      )}
      {address.address}
      <br />
      {address.postalCode?.length > 0 && (
        <>{address.postalCode.replace(/^(\d{3})(\d{2})/, '$1 $2')},</>
      )}{' '}
      {address.city}
    </Box>
  ) : (
    <em>Uppgift saknas</em>
  );
}

Address.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string,
    careOf: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
};
