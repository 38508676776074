import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import TablePaper from '~/components/TablePaper';
import Time from '~/components/Time';

const Events = ({ items }) => (
  <TablePaper title="Logg">
    <List css={{ maxHeight: '800px', overflowY: 'auto' }}>
      {[...items].reverse().map((item, i) => (
        <ListItem key={i} disableGutters divider>
          <ListItemText
            secondary={
              <>
                <Typography component="div" variant="body2">
                  <Time
                    date={new Date(item.timestamp)}
                    format="yyyy-MM-dd HH:mm"
                  />
                </Typography>
                <Typography component="div" variant="body2" color="textPrimary">
                  {item.user && (
                    <>
                      <Link to={`/users/${item.user.id}`}>
                        {item.user.name}
                      </Link>
                      {' | '}
                    </>
                  )}
                  {item.message}{' '}
                  {item.order && <Link to={`/order/${item.order}`}>Order</Link>}{' '}
                </Typography>
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  </TablePaper>
);

Events.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
      order: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }),
  ),
};

export default Events;
