import { useApolloClient, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import { termsType } from '~/propTypes';

import mutation from './_EditTerms.gql';
import TermsForm from './TermsForm';

export default function TermsEdit({ data }) {
  const { id } = useParams();
  const apollo = useApolloClient();

  const [action, { error }] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  const { name, status, version, text } = data;

  if (error) {
    return <ErrorMessage error={error} />;
  }
  return (
    <TermsForm
      initialValues={{
        name,
        status,
        version,
        text,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await action({
          variables: {
            id,
            input: values,
          },
        });

        setSubmitting(false);
      }}
    />
  );
}

TermsEdit.propTypes = {
  data: termsType.isRequired,
};
