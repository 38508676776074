import { Switch, useRouteMatch } from 'react-router-dom';

import { Breadcrumb } from '~/components/Breadcrumbs';
import ErrorBoundRoute from '~/components/ErrorBoundRoute';

import Edition from './Edition';
import EditionAdd from './EditionAdd';
import EditionList from './EditionList';

export default function Editions() {
  const { path } = useRouteMatch();

  return (
    <>
      <Breadcrumb name="Utgåvor" />
      <Switch>
        <ErrorBoundRoute path={path} exact>
          <EditionList />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/add`} exact>
          <EditionAdd />
        </ErrorBoundRoute>
        <ErrorBoundRoute path={`${path}/:edition`}>
          <Edition />
        </ErrorBoundRoute>
      </Switch>
    </>
  );
}
