import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import PropTypes from 'prop-types';

// Clean up __typename from variables so we can post back
// the same object we receives from the backend
const omitTypename = new ApolloLink((operation, forward) => {
  operation.variables = JSON.parse(
    JSON.stringify(operation.variables),
    (key, value) => (key === '__typename' ? undefined : value),
  );

  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError?.statusCode === 401) {
    window.location.href = `${
      process.env.REACT_APP_AUTH_URL
    }?ReturnUrl=${encodeURI(window.location.href)}`;
  }
});

const httpLink = new HttpLink({
  uri: '/api',
  credentials: 'include',
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([omitTypename, errorLink, httpLink]),
});

function Apollo({ children }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

Apollo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Apollo;
