import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { format, parseISO } from 'date-fns';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  icon: {
    marginLeft: '10px',
  },
});

export default function DateInputField({
  name,
  label,
  required,
  clearIconEnabled,
  setFieldValue,
  minDate = new Date(),
  value,
}) {
  const classes = useStyles();
  return (
    <Grid container alignItems="center">
      <Field
        component={TextField}
        name={name}
        InputProps={{
          inputProps: {
            min: format(minDate, 'yyyy-MM-dd'),
          },
        }}
        InputLabelProps={{ shrink: true }}
        label={label}
        variant="outlined"
        type="date"
        value={value ? format(parseISO(value), 'yyyy-MM-dd') : value}
      />
      {!required && (
        <div>
          <IconButton
            classes={{ root: classes.icon }}
            edge="end"
            size="small"
            disabled={!clearIconEnabled}
            onClick={() => setFieldValue(name, '')}
          >
            <ClearIcon />
          </IconButton>
        </div>
      )}
    </Grid>
  );
}

DateInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  clearIconEnabled: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  value: PropTypes.string,
};
