import { Box, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function TablePaper({ title, children, button }) {
  return (
    <Paper
      css={({ theme }) => ({
        padding: theme.spacing(2),
      })}
    >
      <Box display="flex" justifyContent="space-between">
        {title && (
          <Typography component="h2" variant="h6" color="primary">
            {title}
          </Typography>
        )}
        {button}
      </Box>
      {children}
    </Paper>
  );
}

TablePaper.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
  button: PropTypes.node,
};
