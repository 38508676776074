import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Types } from 'trhub-utils';
import * as yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import { NumberField } from '~/components/NumberField';
import { orderPropType } from '~/propTypes';
import toOptions from '~/utils/toOptions';

import mutation from './_RefundOrder.gql';

const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .required('Belopp måste anges.')
    .moreThan(0, 'Belopp måste vara ett positivt heltal.'),

  receiverName: yup.string().required('Mottagarens namn måste anges.'),

  bankgiroNo: yup.string().when('paymentMethod', {
    is: value => ['notification', 'autogiro'].includes(value),
    then: yup.string().when('receivingPaymentMethod', {
      is: value => value === 'BankGiro',
      then: yup.string().required('Bankgironummer måste anges.'),
    }),
  }),

  plusgiroNo: yup.string().when('paymentMethod', {
    is: value => ['notification', 'autogiro'].includes(value),
    then: yup.string().when('receivingPaymentMethod', {
      is: value => value === 'PlusGiro',
      then: yup.string().required('Plusgironummer måste anges.'),
    }),
  }),

  clearingNo: yup.string().when('paymentMethod', {
    is: value => ['notification', 'autogiro'].includes(value),
    then: yup.string().when('receivingPaymentMethod', {
      is: value => value === 'BankAccount',
      then: yup.string().required('Clearingnummer måste anges.'),
    }),
  }),

  accountNo: yup.string().when('paymentMethod', {
    is: value => ['notification', 'autogiro'].includes(value),
    then: yup.string().when('receivingPaymentMethod', {
      is: value => value === 'BankAccount',
      then: yup.string().required('Kontonummer måste anges.'),
    }),
  }),
});

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

export default function OrderRefund({ data }) {
  const classes = useStyles();

  const apollo = useApolloClient();

  const history = useHistory();

  const [error, setError] = useState('');

  const { order } = useParams();

  const { totalPrice, paymentMethod, payee } = data?.getOrder;

  const [action] = useMutation(mutation, {
    onCompleted: async () => {
      // Ensure all lists are refreshed
      await apollo.resetStore();
    },
  });

  return (
    <>
      <Breadcrumb name="Återbetalning" />
      {error.length > 0 && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Återbetalning
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Återbetalningen registreras på ordern, men behöver attesteras av två
            behöriga attestanter.
          </Typography>
        </CardContent>
      </Card>

      <Formik
        initialValues={{
          paymentMethod: paymentMethod,
          receivingPaymentMethod: 'BankGiro',
          receiverName: payee?.name,
          amount: totalPrice / 100,
          bankgiroNo: '',
          plusgiroNo: '',
          clearingNo: '',
          accountNo: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await action({
            variables: {
              input: {
                order,
                ...values,
                amount: Number.parseInt(values.amount),
              },
            },
          }).catch(responseError => setError(responseError.message));
          setSubmitting(false);
          if (response) {
            history.push(`/order/${order}`);
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <Field
              component={TextField}
              name="receiverName"
              label="Mottagarens namn"
              variant="outlined"
            />
            <NumberField name="amount" label="Belopp" value={values.amount} />
            {['notification', 'autogiro'].includes(paymentMethod) && (
              <>
                <Field
                  label="Betalmetod"
                  component={TextField}
                  select
                  name="receivingPaymentMethod"
                  variant="outlined"
                >
                  {toOptions(Types.billectaPaymentMethods).map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Field>
                {values.receivingPaymentMethod === 'BankGiro' && (
                  <Field
                    component={TextField}
                    name="bankgiroNo"
                    label="Bankgironummer"
                    variant="outlined"
                  />
                )}
                {values.receivingPaymentMethod === 'PlusGiro' && (
                  <Field
                    component={TextField}
                    name="plusgiroNo"
                    label="PlusGironummer"
                    variant="outlined"
                  />
                )}
                {values.receivingPaymentMethod === 'BankAccount' && (
                  <>
                    <NumberField
                      component={TextField}
                      name="clearingNo"
                      label="Clearingnummer"
                      variant="outlined"
                      value={values.clearingNo}
                    />
                    <Field
                      component={TextField}
                      name="accountNo"
                      label="Kontonummer"
                      variant="outlined"
                    />
                  </>
                )}
              </>
            )}
            {isSubmitting && <LinearProgress />}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Betala
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

OrderRefund.propTypes = {
  data: PropTypes.shape({
    getOrder: orderPropType,
  }).isRequired,
};
