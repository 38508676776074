import { useQuery } from '@apollo/client';
import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetUnsubscriptionReports.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function UnsubscribeReasonListResult({ state }) {
  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);

  const { error, data } = useQuery(query, {
    variables: {
      productId: state.productId,
      from: state.from,
      to: state.to,
      page: page,
      limit: pageSize,
    },
  });

  const res = data?.getUnsubscriptionReports;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  const headers = [
    'Datum',
    'Prenumerationen uppfyllde ej förväntningar',
    'Inte tid att nyttja prenumerationen',
    'Prenumerationen ansågs vara för dyr',
    'Passerat förfallodatumet i recurring',
    'Övrigt',
  ];

  return (
    <>
      <TableList
        page={page}
        pageSize={pageSize}
        data={res}
        headers={headers}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
      >
        {res?.unsubscriptionReport.length > 0 ? (
          res.unsubscriptionReport.map((row, index) => (
            <TableRow hover key={index}>
              <TableCell className={classes.tableCellDate}>
                {state.from || state.to ? (
                  <>
                    {state.from
                      ? format(new Date(state.from), 'yyyy-MM-dd')
                      : ''}{' '}
                    -{' '}
                    {format(
                      state.to ? new Date(state.to) : new Date(),
                      'yyyy-MM-dd',
                    )}{' '}
                  </>
                ) : (
                  <Time date={new Date(row.date)} />
                )}
              </TableCell>
              <TableCell align="center">{row.expectation}</TableCell>
              <TableCell align="center">{row.limitedTime}</TableCell>
              <TableCell align="center">{row.tooExpensive}</TableCell>
              <TableCell align="center">{row.passedDueDate}</TableCell>
              <TableCell align="center">{row.other}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={5} align="center">
              Inga uppsägningsanledningar finns för denna filtrering. Prova att
              ange en annan produkt, eller ett annat datumintervall.
            </TableCell>
          </TableRow>
        )}
      </TableList>
    </>
  );
}

UnsubscribeReasonListResult.propTypes = {
  state: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};
