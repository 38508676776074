import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Types } from 'trhub-utils';

import DataGrid from '~/components/DataGrid';
import PaymentMethodIcon from '~/components/PaymentMethodIcon';
import Time from '~/components/Time';

import query from './_GetOrders.gql';

const columns = [
  {
    field: 'paymentMethod',
    width: 50,
    headerName: ' ',
    sortable: false,
    renderCell: params => <PaymentMethodIcon paymentMethod={params.value} />,
  },
  {
    field: 'id',
    flex: 2,
    headerName: 'Order',
    sortable: false,
    renderCell: params => (
      <Link component={RouterLink} to={`/order/${params.value}`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: 'user',
    flex: 2,
    headerName: 'Användare',
    sortable: false,
    renderCell: params => (
      <Link component={RouterLink} to={`/users/${params.value.id}`}>
        {params.value.email}
      </Link>
    ),
  },
  {
    field: 'orderStatus',
    flex: 1,
    headerName: 'Status',
    sortable: false,
    valueGetter: params => Types.orderStatus[params.value],
  },

  {
    field: 'created',
    flex: 1,
    headerName: 'Orderdatum',
    renderCell: params => <Time date={new Date(params.value)} />,
  },
  {
    field: 'label',
    flex: 3,
    headerName: 'Produkter',
    sortable: false,
  },
];

export default function OrderListResults({ params }) {
  const { productSku, status, user, orderId } = params;

  return (
    <DataGrid
      columns={columns}
      query={query}
      defaultSort="-created"
      filter={{
        productSku,
        status,
        user,
        search: /^[\dA-Fa-f]{24}$/.test(orderId)
          ? orderId.toString()
          : undefined,
      }}
    />
  );
}
OrderListResults.propTypes = {
  params: PropTypes.shape({
    productSku: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
  }).isRequired,
};
