import { useQuery } from '@apollo/client';
import { CircularProgress, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ErrorMessage from '~/components/ErrorMessage';
import TableList from '~/components/TableList';
import Time from '~/components/Time';
import usePagination from '~/hooks/usePagination';

import query from './_GetAllKeyFigures.gql';
import dateRangeQuery from './_GetKeyFiguresDateRange.gql';

const useStyles = makeStyles(() => ({
  tableRowSum: {
    background: 'linear-gradient(45deg, #2196F3 5%, #21CBF3 30%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}));

export default function KeyFigureListResults({ state }) {
  const classes = useStyles();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(15);

  const { error, data } = useQuery(query, {
    variables: {
      productId: state.productId,
      from: state.from || undefined,
      to: state.to || undefined,
      page: page,
      limit: pageSize,
    },
  });

  const res = data?.getMarketReports;

  const { error: dateRangeKeyFiguresError, data: dateRangeKeyFiguresdata } =
    useQuery(dateRangeQuery, {
      variables: {
        productId: state.productId,
        from: state.from || undefined,
        to: state.to || undefined,
      },
    });

  const dateRangeRes = dateRangeKeyFiguresdata?.getMarketReportDateRange;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!res) {
    return <CircularProgress />;
  }

  if (dateRangeKeyFiguresError) {
    return <ErrorMessage error={dateRangeKeyFiguresError} />;
  }

  const headers = [
    'Datum',
    'Produkt',
    'Nya',
    'Återaktiverad avbruten',
    'Inaktiva',
    'Återaktiverad passiv',
    'Avbrutna',
    'Avslutade',
    'Aktiva',
    'Totalt',
  ];

  return (
    <>
      <TableList
        page={page}
        pageSize={pageSize}
        data={res}
        headers={headers}
        updatePage={updatePage}
        updateRowSize={updateRowSize}
      >
        {res?.reports.length > 0 ? (
          res.reports.map((row, index) => (
            <TableRow hover key={index}>
              <TableCell className={classes.tableCellDate}>
                <Time date={new Date(row.date)} />
              </TableCell>
              <TableCell>{row.product.name}</TableCell>
              <TableCell>{row.newSubscribers}</TableCell>
              <TableCell>{row.reactivatedEndedSubscribers}</TableCell>
              <TableCell>{row.inactiveSubscribers}</TableCell>
              <TableCell>{row.reactivatedPassiveSubscribers}</TableCell>
              <TableCell>{row.cancelledSubscribers}</TableCell>
              <TableCell>{row.endedSubscribers}</TableCell>
              <TableCell>{row.activeSubscribers}</TableCell>
              <TableCell>{row.totalSubscribers}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={10} align="center">
              Inga nyckeltal finns för denna filtrering. Prova att ange en annan
              produkt, eller ett annat datumintervall.
            </TableCell>
          </TableRow>
        )}
        {dateRangeRes?.product && (
          <TableRow className={classes.tableRowSum}>
            <TableCell>Total:</TableCell>
            <TableCell>{dateRangeRes.product.name}</TableCell>
            <TableCell>{dateRangeRes.newSubscribers}</TableCell>
            <TableCell>{dateRangeRes.reactivatedEndedSubscribers}</TableCell>
            <TableCell>{dateRangeRes.inactiveSubscribers}</TableCell>
            <TableCell>{dateRangeRes.reactivatedPassiveSubscribers}</TableCell>
            <TableCell>{dateRangeRes.cancelledSubscribers}</TableCell>
            <TableCell>{dateRangeRes.endedSubscribers}</TableCell>
            <TableCell>{dateRangeRes.activeSubscribers}</TableCell>
            <TableCell>{dateRangeRes.totalSubscribers}</TableCell>
          </TableRow>
        )}
      </TableList>
    </>
  );
}

KeyFigureListResults.propTypes = {
  state: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};
