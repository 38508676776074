import { useQuery } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Link,
  TableCell,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink, NavLink, useLocation } from 'react-router-dom';

import ErrorMessage from '~/components/ErrorMessage';
import NoUserData from '~/components/NoUserData';
import TableList from '~/components/TableList';
import usePagination from '~/hooks/usePagination';

import query from './_GetAccessesByUser.gql';

export default function Bettips({ user }) {
  const { pathname } = useLocation();

  const { page, pageSize, updatePage, updateRowSize } = usePagination(10);

  const { error, loading, data } = useQuery(query, {
    variables: { user, page: page, limit: pageSize },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (loading) {
    return <CircularProgress />;
  }

  const headers = ['Produkt'];

  const res = data?.getAccessesByUser;

  return (
    <TableList
      title="Köpta speltips"
      page={page}
      pageSize={pageSize}
      data={res}
      headers={headers}
      updatePage={updatePage}
      updateRowSize={updateRowSize}
      button={
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          to={`${pathname}/access`}
        >
          Ge gratis access
        </Button>
      }
    >
      {res?.accesses.length > 0 ? (
        res.accesses.map((access, index) => (
          <TableRow key={index}>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/products/${access.product.id}`}
              >
                {access.product.name}
              </Link>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell>
            <NoUserData text="Den här användaren har inga aktiva speltips" />
          </TableCell>
        </TableRow>
      )}
    </TableList>
  );
}

Bettips.propTypes = {
  user: PropTypes.string.isRequired,
};
